<template>
  <div class="stars-container">
    <div class="star"></div>
    <div class="star"></div>
    <div class="star"></div>
    <div class="star"></div>
    <div class="star"></div>
    <div class="star"></div>
    <div class="star"></div>
    <div class="star"></div>
  </div>
</template>

<script>
export default {
  name: "StarsComponent"
}
</script>

<style scoped>
@keyframes blink {
  50% {
    opacity: 0;
  }
}
.star {
  background-image: url("../../assets/star.svg");
  background-size: cover;
  position: absolute;
  z-index: 1;
  transition: 1s;
}
.star:before {
  content: '';
  background-image: url("../../assets/light-star.svg");
  background-repeat: no-repeat;
  position: absolute;
  animation-name: blink;
  animation-timing-function: linear;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.star:nth-child(1){
  width: 47px;
  height: 47px;
  top: 14%;
  left: 28%;
}
.star:nth-child(1):before {
  animation-duration: 1.7s;
  top: -77px;
  opacity: 0.7;
  left: -77px;
  width: 282px;
  height: 282px;
}
.star:nth-child(2){
  width: 27px;
  height: 27px;
  top: 19%;
  left: 25%;
}
.star:nth-child(2):before{
  animation-duration: 1s;
  opacity: 0.3;
  top: -66px;
  left: -66px;
  width: 161px;
  height: 161px;
  background-size: 161px 161px;
}
.star:nth-child(3){
  width: 31px;
  height: 31px;
  top: 32%;
  left: 2%;
}
.star:nth-child(3):before{
  animation-duration: 1.12s;
  opacity: 0.46;
  top: -76px;
  left: -76px;
  width: 185px;
  height: 185px;
  background-size: 185px 185px;
}
.star:nth-child(4){
  width: 47px;
  height: 47px;
  top: 52%;
  left: 25%;
}
.star:nth-child(4):before{
  animation-duration: 1.7s;
  opacity: 0.7;
  top: -116px;
  left: -116px;
  width: 281px;
  height: 281px;
  background-size: 281px 281px;
}
.star:nth-child(5){
  width: 43px;
  height: 43px;
  top: 61%;
  left: 30%;
}
.star:nth-child(5):before{
  animation-duration: 1.55s;
  opacity: 0.64;
  top: -106px;
  left: -106px;
  width: 257px;
  height: 257px;
  background-size: 257px 257px;
}
.star:nth-child(6){
  width: 32px;
  height: 32px;
  top: 14%;
  left: 73%;
}
.star:nth-child(6):before{
  animation-duration: 1.15s;
  opacity: 0.47;
  top: -79px;
  left: -79px;
  width: 191px;
  height: 191px;
  background-size: 191px 191px;
}
.star:nth-child(7){
  width: 52px;
  height: 52px;
  top: 29%;
  left: 95%;
}
.star:nth-child(7):before{
  animation-duration: 1.88s;
  opacity: 0.77;
  top: -130px;
  left: -130px;
  width: 310px;
  height: 310px;
  background-size: 310px 310px;
}
.star:nth-child(8){
  width: 32px;
  height: 32px;
  top: 45%;
  left: 67%;
}
.star:nth-child(8):before{
  animation-duration: 1.15s;
  opacity: 0.5;
  top: -78px;
  left: -78px;
  width: 191px;
  height: 191px;
  background-size: 191px 191px;
}
@media screen and (max-width: 1100px) {
  .star:nth-child(1){
    scale: 0.5;
    top: 78px;
    left: 53px;
  }
  .star:nth-child(2){
    scale: 0.6;
    top: 108px;
    left: 44px;
  }
  .star:nth-child(3){
    scale: 0.8;
    top: 346px;
    left: 19px;
  }
  .star:nth-child(4){
    opacity: 0;
  }
  .star:nth-child(5){
    opacity: 0;
  }
  .star:nth-child(6){
    top: 141px;
    left: auto;
    right: 10px;
  }
  .star:nth-child(7){
    opacity: 0;
  }
  .star:nth-child(8){
    scale: 0.8;
    top: 366px;
    left: auto;
    right: 23px;
  }


  .content-with-result .star:nth-child(1){
    top: 128px;
    left: 9px;
  }
  .content-with-result .star:nth-child(2){
    top: 158px;
    left: 0;
  }
  .content-with-result .star:nth-child(3){
    scale: 0.8;
    top: 496px;
    left: 7px;
  }
  .content-with-result .star:nth-child(4) {
    opacity: 1;
    top: 724px;
    left: 11px;
    scale: 0.7;
  }
  .content-with-result .star:nth-child(5) {
    opacity: 1;
    top: 565px;
    scale: 0.7;
    left: 130px;
  }
  .content-with-result .star:nth-child(6){
    top: 143px;
    scale: 0.7;
  }
  .content-with-result .star:nth-child(7){
    opacity: 1;
    top: 682px;
    left: auto;
    right: -6px;
    scale: 0.5;
  }
  .content-with-result .star:nth-child(8){
    scale: 1;
    top: 502px;
    right: 6px;
  }
}
</style>