import { createApp } from 'vue'
import App from './App.vue'

createApp(App).mount('#app')

document.addEventListener('DOMContentLoaded', scaleLandImage);
window.onresize = scaleLandImage;

function scaleLandImage(){
    if(window.innerWidth > 1920) {
        document.querySelector('.land').style.scale = window.innerWidth / 1920
    } else {
        document.querySelector('.land').style.scale = 'none'
    }
}