<template>
  <div class="header">
    <div class="logo-container">
      <img src="../assets/belacola-logo.png" alt="Бела-Кола" class="logo">
      <img src="../assets/belacola-logo.png" alt="Бела-Кола" class="logo">
      <img src="../assets/belacola-logo.png" alt="Бела-Кола" class="logo">
      <img src="../assets/belacola-logo.png" alt="Бела-Кола" class="logo">
      <img src="../assets/belacola-logo.png" alt="Бела-Кола" class="logo">
      <img src="../assets/belacola-logo.png" alt="Бела-Кола" class="logo">
    </div>
    <div class="lang-switcher">
      <p :class="selectedLang === 'ru' ? 'active' : ''" @click="changeLang('ru')">РУ</p>
      <p :class="selectedLang === 'by' ? 'active' : ''" @click="changeLang('by')">БЕЛ</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderComponent",
  props: ['selectedLang'],
  methods: {
    changeLang(lang){
      this.$parent.changeLang(lang)
    }
  }
}
</script>

<style scoped>

  .header {
    height: 100px;
    background: #D6001C;
    box-shadow: 0 18px 64px -15px #00000040;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
  }
  .logo-container {
    display: flex;
    padding-left: 60px;
  }
  .logo-container .logo {
    width: 268px;
    height: 46px;
    object-fit: cover;
    margin-right: 0;
  }
  .logo-container .logo:last-child {
    margin-right: 0;
  }
  .lang-switcher {
    padding-right: 55px;
    display: flex;
  }
  .lang-switcher p{
    font-size: 32px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0;
    text-align: left;
    color: #BBBBBB;
    margin: 0 30px 0 0;
    cursor: pointer;
    position: relative;
  }
  .lang-switcher p:hover,
  .lang-switcher p.active{
    color: #fff;
  }
  .lang-switcher p.active:after{
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #fff;
    bottom: -5px;
    left: 0;
  }
  .lang-switcher p:last-child{
    margin: 0;
  }

  @media screen and (max-width: 1800px) {
    .logo-container .logo:nth-child(6) {
      display: none;
    }
  }
  @media screen and (max-width: 1600px) {
    .logo-container .logo:nth-child(5) {
      display: none;
    }
  }
  @media screen and (max-width: 1330px) {
    .logo-container .logo:nth-child(4) {
      display: none;
    }
  }
  @media screen and (max-width: 1060px) {
    .logo-container .logo:nth-child(3) {
      display: none;
    }
  }
  @media screen and (max-width: 800px) {
    .logo-container .logo:nth-child(2) {
      display: none;
    }
  }
  @media screen and (max-width: 600px) {
    .header {
      height: 82px;
      align-items: end;
    }
    .lang-switcher p:last-child{
      margin-bottom: 23px;
      margin-right: 0;
    }
    .lang-switcher p {
      font-size: 16px;
      font-weight: 700;
      line-height: 16px;
      margin-bottom: 23px;
      margin-right: 15px;
    }
    .logo-container .logo {
      width: 131px;
      height: 33px;
      padding-bottom: 12px;
    }
    .logo-container {
      padding-left: 25px;
    }
    .lang-switcher {
      padding-right: 25px;
    }
  }

</style>