<template>
  <div :class="horoscopeSelected ? 'content content-with-result' : 'content'">

    <horoscope :selected-lang="selectedLang" @horoscopeSelect="horoscopeSelect"></horoscope>
    <dragon></dragon>
    <land></land>
    <stars></stars>
    <snowflakes></snowflakes>
    <clouds></clouds>
  </div>
</template>

<script>
import Land from './elements/Land.vue'
import Dragon from './elements/Dragon.vue'
import Stars from './elements/Stars.vue'
import Snowflakes from './elements/Snowflakes.vue'
import Clouds from './elements/Clouds.vue'
import Horoscope from './elements/Horoscope.vue'
export default {
  name: "ContentComponent",
  components: {
    Land,
    Horoscope,
    Stars,
    Snowflakes,
    Clouds,
    Dragon
  },
  props: ['selectedLang'],
  data() {
    return {
      horoscopeSelected: false
    }
  },
  methods: {
    horoscopeSelect(result){
      this.horoscopeSelected = result
    }
  }
}
</script>

<style scoped>
  .content {
    height: calc(100vh - 160px);
    padding-top: 100px;
    padding-bottom: 60px;
    background: #D6001C;
    position: relative;
    overflow: hidden;
  }
  @media screen and (max-width: 1100px) {
    .content {
      min-height: 495px;
    }
    .content.content-with-result {
      min-height: 850px;
    }
  }
</style>