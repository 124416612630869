<template>
  <div class="footer">
    <div class="contact">
      <a href="tel:+375 17 377 95 06">+375 17 377 95 06</a>
      <p>ул. Макаенка 19</p>
    </div>
    <div class="social">
      <a href="https://www.instagram.com/bela_cola_official/">
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_143_3524)">
            <path d="M12.0415 1.33331H4.95817C3.00216 1.33331 1.4165 2.8257 1.4165 4.66665V11.3333C1.4165 13.1743 3.00216 14.6666 4.95817 14.6666H12.0415C13.9975 14.6666 15.5832 13.1743 15.5832 11.3333V4.66665C15.5832 2.8257 13.9975 1.33331 12.0415 1.33331Z" stroke="#1A517D" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11.3333 7.58002C11.4207 8.13485 11.32 8.7015 11.0455 9.19936C10.7711 9.69723 10.3368 10.101 9.80444 10.3531C9.27211 10.6053 8.66886 10.6931 8.08047 10.604C7.49209 10.5149 6.94854 10.2534 6.52713 9.85679C6.10573 9.46018 5.82793 8.9486 5.73325 8.39483C5.63857 7.84105 5.73183 7.27328 5.99977 6.77227C6.2677 6.27125 6.69667 5.86251 7.22565 5.60418C7.75464 5.34585 8.3567 5.25108 8.94621 5.33335C9.54753 5.41727 10.1042 5.68099 10.5341 6.08556C10.9639 6.49012 11.2441 7.01407 11.3333 7.58002Z" stroke="#1A517D" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.396 4.33331H12.4035" stroke="#1A517D" stroke-linecap="round" stroke-linejoin="round"/>
          </g>
          <defs>
            <clipPath id="clip0_143_3524">
              <rect width="17" height="16" fill="white"/>
            </clipPath>
          </defs>
        </svg>
        belacola
      </a>
      <a href="https://mzbn.com/">
        <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_141_7321)">
            <path d="M7.5 14.6666C10.9518 14.6666 13.75 11.6819 13.75 7.99998C13.75 4.31808 10.9518 1.33331 7.5 1.33331C4.04822 1.33331 1.25 4.31808 1.25 7.99998C1.25 11.6819 4.04822 14.6666 7.5 14.6666Z" stroke="#1A517D" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M1.25 8H13.75" stroke="#1A517D" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.5 1.33331C9.0633 3.15888 9.95172 5.528 10 7.99998C9.95172 10.472 9.0633 12.8411 7.5 14.6666C5.9367 12.8411 5.04828 10.472 5 7.99998C5.04828 5.528 5.9367 3.15888 7.5 1.33331Z" stroke="#1A517D" stroke-linecap="round" stroke-linejoin="round"/>
          </g>
          <defs>
            <clipPath id="clip0_141_7321">
              <rect width="15" height="16" fill="white"/>
            </clipPath>
          </defs>
        </svg>
        mzbn.com
      </a>
    </div>
  </div>

</template>

<script>
export default {
  name: "FooterComponent"
}
</script>

<style scoped>
  .footer {
    box-sizing: border-box;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 60px;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 999;
  }
  .footer p,
  .footer a{
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0;
    margin: 0;
    text-decoration: none;
    color: #1A517D;
  }
  .footer p svg,
  .footer a svg{
    margin-right: 5px;
  }
  .footer .contact,
  .footer .social{
    display: flex;
  }
  .footer .contact a:first-child,
  .footer .social a:first-child{
    margin-right: 60px;
  }
  @media screen and (max-width: 700px) {
    .footer {
      padding: 0 22px;
    }
    .footer .contact,
    .footer .social{
      flex-direction: column;
    }
    .footer .social a:first-child,
    .footer .contact a:first-child{
      margin-bottom: 6px;
      margin-right: 0;
    }
  }
</style>