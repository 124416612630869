<template>

  <Header :selected-lang="selectedLang" @changeLang="changeLang"></Header>

  <Content :selected-lang="selectedLang"></Content>

  <Footer></Footer>

</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import Content from './components/Content.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    Content
  },
  data() {
    return {
      selectedLang: 'by'
    }
  },
  methods: {
    changeLang(lang){
      this.selectedLang = lang
    }
  }
}
</script>

<style>
  body {
    margin: 0;
    font-family: Montserrat, sans-serif;
  }
</style>
