<template>
  <div class="snowflakes-container">
    <img v-for="n in 60" :key="n" src="../../assets/snowflake.svg" alt="Snowflakes" class="snowflake" :style="getSnowflakeRandomStyle()">

  </div>
</template>

<script>
export default {
  name: "SnowflakesComponent",
  methods: {
    getSnowflakeRandomStyle(){
      let width = this.randomInteger(30, 50)
      let top = this.randomInteger(-60, 60)
      let left = this.randomInteger(0, 100)
      let animationTime = this.randomInteger(7, 15)
      let leftIni = this.randomInteger(0, 200)
      let leftEnd = this.randomInteger(0, 200)
      return '--width: ' + width + 'px;' +
             '--height: ' + width + 'px;' +
             '--top: ' + top + '%;' +
             '--left: ' + left + '%;' +
             '--animation-time: ' + animationTime + 's;' +
             '--left-ini: ' + leftIni + 'px;' +
             '--left-end: ' + leftEnd + 'px;'
    },
    randomInteger(min, max) {
      return Math.round(Math.random() * (max - min) + min)
    }
  }
}
</script>

<style scoped>
  .snowflake {
    position: absolute;
    width: var(--width);
    height: var(--height);
    top: var(--top);
    left: var(--left);
    animation: snowfall var(--animation-time) linear infinite;

  }

  @keyframes snowfall {
    0% {
      transform: translate3d(var(--left-ini), 0, 0);
    }
    100% {
      transform: translate3d(var(--left-end), 110vh, 0);
    }
  }
</style>