<template>
  <div class="cloud-container">
    <img src="../../assets/cloud1.svg" alt="Cloud" class="cloud">
    <img src="../../assets/cloud2.svg" alt="Cloud" class="cloud">
    <img src="../../assets/cloud3.svg" alt="Cloud" class="cloud">
    <img src="../../assets/cloud4.svg" alt="Cloud" class="cloud">
    <img src="../../assets/cloudMob1.svg" alt="Cloud" class="cloud cloud-mobile">
    <img src="../../assets/cloudMob2.svg" alt="Cloud" class="cloud cloud-mobile">
    <img src="../../assets/cloudMob3.svg" alt="Cloud" class="cloud cloud-mobile">
    <img src="../../assets/cloudMob4.svg" alt="Cloud" class="cloud cloud-mobile">
  </div>
</template>

<script>
export default {
  name: "CloudsComponent"
}
</script>

<style scoped>
  .cloud {
    position: absolute;
    z-index: 2;
    transition: 1s;
  }
  .cloud-mobile {
    display: none;
  }
  .cloud:nth-child(1){
    top: 15%;
    left: 2%;
  }
  .content-with-result .cloud:nth-child(1){
    top: 11%;
    left: 1%;
  }
  .cloud:nth-child(2){
    top: 46%;
    left: 7px;
  }
  .content-with-result .cloud:nth-child(2){
    top: 51%;
    left: -7%;
  }
  .cloud:nth-child(3){
    top: 14%;
    right: 32%;
  }
  .cloud:nth-child(4){
    top: 16%;
    right: 0;
  }

  @media screen and (max-width: 1100px) {
    .cloud {
      display: none;
    }
    .cloud.cloud-mobile {
      display: block;
    }
    .cloud-mobile:nth-child(5) {
      top: 100px;
      left: -60px;
      scale: 1.5;
    }
    .cloud-mobile:nth-child(6) {
      top: 390px;
      left: -3px;
    }
    .cloud-mobile:nth-child(7) {
      top: 90px;
      right: -5px;
    }
    .cloud-mobile:nth-child(8) {
      top: 390px;
      right: -120px;
    }
    .content-with-result .cloud-mobile:nth-child(5) {
      top: 100px;
      left: -2px;
      scale: 1;
    }
    .content-with-result .cloud-mobile:nth-child(6) {
      top: 540px;
      left: -30px;
      scale: 1.4;
    }
    .content-with-result .cloud-mobile:nth-child(7) {
      right: -20px;
    }
    .content-with-result .cloud-mobile:nth-child(8) {
      top: 540px;
      right: 0;
    }
  }
</style>